/**
 * Created by front on 2019-10-28.
 */

let helper = {
    getRenderedSizePosition: function(contains, cWidth, cHeight, width, height, posWidth, posHight) {
        let oRatio = width / height;
        let cRatio = cWidth / cHeight;
        return (function () {
            if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
                this.width = cWidth;
                this.height = cWidth / oRatio;
            } else {
                this.width = cHeight * oRatio;
                this.height = cHeight;
            }
            this.left = (cWidth - this.width) * (posWidth / 100);
            this.right = this.width + this.left;
            this.top = (cHeight - this.height) * (posHight / 100);
            this.bottom = this.height + this.top;
            return this;
        }.call({}));
    },
    getRelativeVideoSizePosition: function (contains, cWidth, cHeight, posWidth, posHight) {
        return this.getRenderedSizePosition(contains,
            cWidth,
            cHeight,
            640,
            480,
            posWidth,
            posHight);
    },
    trackingPolyLines: function (homography, polyLines, viewWidth, viewHeight, remoteVideo) {
        let pos = window.getComputedStyle(remoteVideo).getPropertyValue('object-position').split(' ');  // % 포지션만 지원
        let relVideoSizePosition = this.getRelativeVideoSizePosition(false, remoteVideo.scrollWidth, remoteVideo.scrollHeight, parseInt(pos[0]), parseInt(pos[1]));
        viewWidth = relVideoSizePosition.width;
        viewHeight = relVideoSizePosition.height;
        let preViewWidth = viewWidth;
        let preViewHeight = viewHeight;
        // 이전에 보내 준 포인트의 기준 좌표계를 현재 원격 화면의 좌표계로 보정 하기 위한 비율 계산
        let xRatio = viewWidth / preViewWidth;
        let yRatio = viewHeight / preViewHeight;

        polyLines = JSON.parse(JSON.stringify(polyLines));

        for (let lineIndex = 0; lineIndex < polyLines.length; lineIndex++) {
            let polyLine = polyLines[lineIndex];
            for (let pointIndex = 0; pointIndex < polyLine.points.length; pointIndex++) {
                let point = polyLine.points[pointIndex];

                let tempX = point.x * homography[0] + point.y * homography[1] + homography[2];
                let tempY = point.x * homography[3] + point.y * homography[4] + homography[5];
                let divide = point.x * homography[6] + point.y * homography[7] + homography[8];

                point.x = parseInt((tempX / divide) * xRatio) + relVideoSizePosition.left;
                point.y = parseInt((tempY / divide) * yRatio) + relVideoSizePosition.top;
            }
        }
        return polyLines;
    },
    guid: function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

};

module.exports = helper;