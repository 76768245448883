
function PeerClient (peer = null, role) {
    this.peer = peer;
    this.remoteSDP = null;
    this.remoteCandidates = [];
    this.role = role || '';
    this.localVideo = null;
    this.remoteVideo = document.createElement('video');
    this.localStream = null;
    this.localPCStream = null;
    // this.remoteStream = [];
    this.remoteStream = null;
    this.localTransSender = null;
}

PeerClient.prototype.init = function  () {
	// eslint-disable-next-line no-console
    console.log('PeerClientInit occured');

    if (this.peer) {
        if (this.peer.receiveMessageDataChannel) {
            this.peer.receiveMessageDataChannel.close();
            // this.peer.receiveMessageDataChannel = null;
        }
        if (this.peer.receiveFileDataChannel) {
            this.peer.receiveFileDataChannel.close();
            // this.peer.receiveFileDataChannel = null;
        }
        if (this.peer.receivePoseMatrixChannel) {
            this.peer.receivePoseMatrixChannel.close();
            // this.peer.receivePoseMatrixChannel = null;
        }
        this.peer.close();
        this.peer = null;
    }

    // if (this.localStream) {
    //     this.localStream.getTracks().forEach(track => track.stop());
    // }
    // if (this.remoteStream) {
    //     this.remoteStream.getTracks().forEach(track => track.stop());
    // }


    this.localVideo = null;
    this.remoteVideo = document.createElement('video');
    this.localStream = null;
    this.remoteStream = null;
    this.localTransSender = null;
    this.localPCTransceiver = null;

    return Promise.resolve(this);
};

PeerClient.prototype.setRemoteVideo = function  (remoteVideo) {
    this.remoteVideo = remoteVideo;
};

module.exports = {
    PeerClient: PeerClient,
};
