var commonService = require('./common');

class Http {
}
let http = new Http();
module.exports = http;
commonService.mergeEventEmitterPrototype(http, ['GetGroups']);
function getGroups (sign) {
	ajaxGETRequestAsync({
		method: 'GET',
		url: 'api/Groups',
		headers: {
			'Authorization': 'Sign ' + sign
		}
	}).then(function (groups) {
		groups = commonService.jsonParse(groups);
		module.exports.trigger('GetGroups', [true, groups]);
	}).catch(function (error) {
		module.exports.trigger('GetGroups', [false, parseInt(error.message)]);
	});
}
http.getGroups = getGroups;

function ajaxGETRequestAsync (options) {
	return new Promise(function (resolve, reject) {
		if (window.XMLHttpRequest) { // Mozilla, Safari, ...
			var httpRequest = new XMLHttpRequest();
		} else if (window.ActiveXObject) { // IE
			try {
				httpRequest = new window.ActiveXObject('Msxml2.XMLHTTP');
			} catch (e) {
				try {
					httpRequest = new window.ActiveXObject('Microsoft.XMLHTTP');
				} catch (e) {
					// eslint-disable-next-line no-console
					console.log(e)
				}
			}
		}

		if (!httpRequest) {
			return reject(new Error(-1));
		}
		httpRequest.onreadystatechange = function () {
			if (httpRequest.readyState === 4) {
				if (httpRequest.status === 200) {
					return resolve(httpRequest.responseText);
				} else {
					return reject(new Error(httpRequest.status));
				}
			}
		};
		httpRequest.open('GET', options.url);
		if (options.headers && typeof options.headers === 'object') {
			for (let key in options.headers) {
				if (Object.prototype.hasOwnProperty.call(options.headers, key)) {
					let value = options.headers[key];
					httpRequest.setRequestHeader(key, value);
				}

			}
		}
		httpRequest.send();
	});
}
