require('webrtc-adapter');
require('mootools');
let DetectRTC = require('../detectRtc');
let commonService = require('./common');
let fileService = require('./file');
let httpService = require('./http');
let callService = require('./call');
let messageService = require('./message');
let wsService = require('./ws');
let helper = require('./helper');
let vivar = {};
let defaultGroup = {extra: "", id: "", name: ""}

let fileManager = new fileService.FileManager();
let RTCConnectionManager = new callService.RTCConnectionManager();
let RTCConnection = callService.RTCConnection;

let webSocket = null;
let sdpConstraints = {
	optional: [],
	mandatory: {
		OfferToReceiveAudio: true,
		OfferToReceiveVideo: true,
	}
};
let dataChannelOptions = {
	ordered: true, // 순서 보장 안함
	maxPacketLifeTime: 3000 // 밀리초 단위
};
let dataChannelOptions_poseMatrix = {
	ordered: true, // 순서 보장 안함
	maxRetransmits: 1
}
let mediaStreamConstraints = {
	audio: false,
	video: false,
};

function init() {
	commonService.checkMediaDevice(function(info) {
		if (info.hasMicrophone) {
			mediaStreamConstraints.audio = vivar.config.audio;
			// sdpConstraints.mandatory.OfferToReceiveAudio = false;
		}
		if (info.hasWebcam) {
			mediaStreamConstraints.video = vivar.config.video;
		}
		if (!info.hasMicrophone && !info.hasWebcam) {
			// 카메라, 마이크 둘다 없으면
			mediaStreamConstraints.audio = false;
			mediaStreamConstraints.video = false;
			// sdpConstraints.mandatory.OfferToReceiveAudio = false;
			// sdpConstraints.mandatory.OfferToReceiveVideo = false;
			alert('카메라 또는 마이크 둘다 없으면 영상통화가 불가 합니다!');
		}
		console.log(mediaStreamConstraints)
	});

	return 0;
}

function processSignalingProtocol(fromId, messageType, msg) {
	if (!RTCConnectionManager.iceServer) {
		console.warn('RTCConnectionManager.iceServer: iceServer is not ready');
		return;
	}
	fromId = parseInt(fromId);
	let rtcConnection = RTCConnectionManager.getConnection(fromId);
	let message = JSON.parse(msg);

	switch (messageType) {
		case 'requestCall':
			console.log('[From:', fromId, '] requestCall : ', message);
			// 상대방으로 부터 통화 올때

			if (message.members.length === 0) {
				console.warn('requestGroupCall: requestCall is not ready')
				break;
			}
			if (RTCConnectionManager.getId() && RTCConnectionManager.getId() !== message.uuid) {
				// 통화중인데 다른그룹에서 requestCall 왔을때
				console.warn('[Group From:' + message.uuid + '] Other opponent Group requestCall Skip : ', message);
				// alert('Other opponent\'s requestCall Skip : ');
				break;
			}

			if (rtcConnection === undefined) {
				if (!RTCConnectionManager.getId()) { // id 가 없으면
					RTCConnectionManager.setId(message.uuid);
				}

				rtcConnection = new RTCConnection(vivar.me.id, fromId);
				RTCConnectionManager.setMembers(message.members);
				RTCConnectionManager.setGroup(message.group);

				RTCConnectionManager.add(rtcConnection);

				if (parseInt(vivar.me.id) < fromId) { // 자신보다 높은 id offer 큐에 담는다.
					let peer = createPeerConnection(rtcConnection);
					rtcConnection.setPeerConnection(peer);
					RTCConnectionManager.addOfferQueue(fromId);
					console.log('[RTCConnectionManager.addOfferQueue] --- ', RTCConnectionManager);
				}

				vivar.me.state = 'busy';
				wsService.postState();
				vivar.trigger('UpdateState', [vivar.me]);
				vivar.trigger('IncomingCall', [RTCConnectionManager.getMember(fromId), RTCConnectionManager.getMembers(), RTCConnectionManager.getGroup()]);
			} else {
				// do something
			}

			break;

		case 'inviteCall':
			console.log('[From:', fromId, '] inviteCall : ', message);
			// 상대방에게 통화 걸때
			let members = message.members;
			let group = message.group;
			if (members.length === 0) {
				console.warn('inviteCall: inviteCall is not ready');
				break;
			}
			if (RTCConnectionManager.getId() && RTCConnectionManager.getId() !== message.uuid) {
				// 통화중인데 다른그룹에서 inviteCall 왔을때
				console.warn('[Group From:' + message.uuid + '] Other opponent Group inviteCall Skip : ', message);
				// alert('Other opponent\'s acceptCall Skip : ');
				break;
			}

			if (rtcConnection === undefined) {
				if (!RTCConnectionManager.getId()) { // id 가 없으면
					RTCConnectionManager.setId(message.uuid);
				}

				rtcConnection = new RTCConnection(vivar.me.id, fromId);
				RTCConnectionManager.setMembers(members);
				RTCConnectionManager.setGroup(group);
				RTCConnectionManager.add(rtcConnection);
			}

			if (parseInt(vivar.me.id) < fromId) { // 자신보다 높은 id offer queue에 담는다.
				let rtcConnection = RTCConnectionManager.getConnection(fromId);
				if (rtcConnection === undefined) {
					rtcConnection = new RTCConnection(vivar.me.id, fromId);
					RTCConnectionManager.add(rtcConnection);
				}
				let peer = createPeerConnection(rtcConnection);
				rtcConnection.setPeerConnection(peer);
				RTCConnectionManager.addOfferQueue(fromId);
				console.log('[RTCConnectionManager.addOfferQueue] --- ', RTCConnectionManager);
			}

			vivar.me.state = 'busy';
			wsService.postState();
			vivar.trigger('UpdateState', [vivar.me]);
			vivar.trigger('IncomingCall', [RTCConnectionManager.getMember(fromId), RTCConnectionManager.getMembers(), RTCConnectionManager.getGroup()]);
			break;
		case 'acceptCall':
			console.log('[From:', fromId, '] acceptCall : ', message);
			// 상대가 통화 승인했을때
			if (!RTCConnectionManager.getId() || RTCConnectionManager.getId() !== message.uuid) {
				// 다른 그룹에서 acceptCall 왔을 때
				console.warn('[Group From:' + message.uuid + '] Other opponent Group acceptCall Skip : ', message);
				// alert('Other opponent\'s acceptCall Skip : ');
			} else {
				if (rtcConnection === undefined) {
					if (!RTCConnectionManager.getId()) { // id 가 없으면
						RTCConnectionManager.setId(message.uuid);
					}

					rtcConnection = new RTCConnection(vivar.me.id, fromId);
					let members = message.members;
					if (RTCConnectionManager.getMembers().length !== members.length) { //todo length로 하지 말자 - 초대하는 경우
						// 이미 통화 연결된 사람이(방장이 아닌) 초대 수락한 사람에게 나 이미 통화중이라고 알려주는 상황
						let member = members.find((member) => {
							return parseInt(member.id) === fromId;
						});
						RTCConnectionManager.setMember(member);

						let group = RTCConnectionManager.getGroup();
						group.extra = JSON.stringify(group.extra);
						let message = { uuid: RTCConnectionManager.getId(), group: group, members: members };
						wsService.sendMessageToMember(member.id, 'acceptCall', JSON.stringify(message), Date.now());
						console.log('[to:', member.id, '] acceptCall send : ', message);

					}
					RTCConnectionManager.add(rtcConnection);
				} else {
					//todo do something
				}

				if (parseInt(vivar.me.id) < fromId) { // 자신보다 높은 id offer queue에 담는다.
					let peer = createPeerConnection(rtcConnection);
					rtcConnection.setPeerConnection(peer);
					RTCConnectionManager.addOfferQueue(fromId);
					console.log('[RTCConnectionManager.addOfferQueue] --- ', RTCConnectionManager);
				}

				if (RTCConnectionManager.isReadytoCallConnect) {
					doOffer();
				}
				vivar.trigger('AcceptCall', [RTCConnectionManager.getMember(fromId)]);
			}

			break;
		case 'cancelCall':
			console.log('[From:', fromId, '] Cancel Call : ', message);

			// 내가 통화 취소했을때
			if (!RTCConnectionManager.getId() || RTCConnectionManager.getId() !== message.uuid) {
				// 다른 그룹에서 cancelCall 왔을 때
				console.warn('[Group From:' + message.uuid + '] Other opponent Group cancelCall Skip : ', message);
				// alert('Other opponent\'s cancelCall Skip : ');
			} else {
				let member = RTCConnectionManager.getMember(fromId);
				let isCaller = member.isCaller;
				RTCConnectionManager.removeConnection(fromId);
				if (Object.keys(RTCConnectionManager.connections).length === 0 || isCaller) {
					// 연결된 객체가 더이상 없을 때
					initCallConfig();
					vivar.trigger('CallDisconnected', [null, true]);
				} else {
					vivar.trigger('CallDisconnected', [rtcConnection, false]);
				}
			}

			break;
		case 'rejectCall':
			console.log('[From:', fromId, '] Reject Call : ', message);

			// 상대가 통화 거절했을때
			if (!RTCConnectionManager.getId() || RTCConnectionManager.getId() !== message.uuid) {
				// 다른 그룹에서 rejectCall 왔을 때
				console.warn('[Group From:' + message.uuid + '] Other opponent Group rejectCall Skip : ', message);
				//alert('Other opponent\'s rejectCall Skip : ');
			} else {
				let member = RTCConnectionManager.getMember(fromId);
				let isCaller = member.isCaller;
				RTCConnectionManager.removeConnection(fromId);
				if (Object.keys(RTCConnectionManager.connections).length === 0 || isCaller) {
					// 연결된 객체가 더이상 없을 때
					initCallConfig();
					vivar.trigger('RejectCall', [member, true]);
				} else {
					vivar.trigger('RejectCall', [member, false]);
				}
			}

			break;
		case 'endCall':
			console.log('[From:' + fromId + '] End Call : ' + message + ', remoteUserId : ' + fromId);

			// 통화중 , 상대가 통화 종료 했을때
			if (!RTCConnectionManager.getId() || RTCConnectionManager.getId() !== message.uuid) {
				// 다른 그룹에서 endCall 왔을 때
				console.warn('[Group From:' + message.uuid + '] Other opponent Group endCall Skip : ', message);
				// alert('Other opponent\'s endCall Skip : ');
			} else {
				if (rtcConnection === undefined) {
					console.warn(`endCall -[From: ${fromId}] already this rtcConnection removed`);
					break;
				}

				if (rtcConnection && fromId === rtcConnection.remoteUserId) {
					let member = RTCConnectionManager.getMember(fromId);
					let isCaller = member.isCaller;
					RTCConnectionManager.removeConnection(fromId);

					if (Object.keys(RTCConnectionManager.connections).length === 0 || isCaller) {
						// 연결된 객체가 더이상 없거나 방장이 전화 끊었을 때
						initCallConfig();
						vivar.trigger('CallDisconnected', [null, true]);
					} else {
						vivar.trigger('CallDisconnected', [rtcConnection, false]);
					}
				}
			}

			break;
		case 'signalingFail':
			console.log('[From:', fromId, '] signalingFail : ', message);

			//  방장만 할 수 있는 권한. 상대방이 응답이 없을 경우(ex.폰이 꺼져 있는 상태) 다른 상대한테 알려주는
			if (!RTCConnectionManager.getId() || RTCConnectionManager.getId() !== message.uuid) {
				// 다른 그룹에서 signalingFail 왔을 때
				console.warn('[Group From:' + message.uuid + '] Other opponent Group signalingFail Skip : ', message);
				//alert('Other opponent\'s rejectCall Skip : ');
			} else {
				let rtcConnection = RTCConnectionManager.getConnection(message.userId);
				let member = RTCConnectionManager.getMember(message.userId);
				let isCaller = member.isCaller;
				RTCConnectionManager.removeConnection(message.userId);
				if (Object.keys(RTCConnectionManager.connections).length === 0 || isCaller) {
					// 연결된 객체가 더이상 없을 때
					initCallConfig();
					vivar.trigger('CallDisconnected', [null, true]);
				} else {
					vivar.trigger('CallDisconnected', [rtcConnection, false]);
				}
			}

			break;
		case 'sdp':
			if (rtcConnection && rtcConnection.remoteUserId === fromId) {
				if (RTCConnectionManager.isReadytoCallConnect) {
					let remoteSdp = message;

					if (remoteSdp.type === 'offer') {
						if (rtcConnection.getPeerConnection()) { // 이미 peerConnection set 되어있을때 (똑같은 offer가 두번 왓을때)
							console.warn('[From:', fromId, '][ Already Remote peerConnection Set : ');
						} else {
							let peer = createPeerConnection(rtcConnection);
							rtcConnection.setPeerConnection(peer);
						}

						rtcConnection.addLocalStream(RTCConnectionManager.getLocalStream());

						rtcConnection.setRemoteSDP(remoteSdp).then(() => {
							doAnswer(rtcConnection);
							rtcConnection.isRemoteSDPSet = true;
							rtcConnection.registerCandidate();
							console.log('[From:', fromId, '][callFrom] Remote SDP Set : ', remoteSdp);
						});
					} else if (remoteSdp.type === 'answer') {
						rtcConnection.setRemoteSDP(remoteSdp).then(() => {
							rtcConnection.isRemoteSDPSet = true;
							rtcConnection.registerCandidate();
							console.log('[From:', fromId, '][callTo] Remote SDP Set : ', remoteSdp);

						}).catch(onfailure);
					} else {
						console.warn('[From:', fromId, '] abnormal SDP message  : ', message);
					}

				} else {
					//todo do something
				}
			} else {
				console.warn('[From:', fromId, '][callFrom] Other opponent\'s SDP Skip : ', message);
			}
			break;
		case 'candidate':
			if (rtcConnection && rtcConnection.remoteUserId === fromId) {
				let candidate = message;
				rtcConnection.registerCandidate(candidate).then(() => {
					console.log('[From:', fromId, '] Remote candidate Set : ', candidate);
				}, onfailure)
			} else {
				console.warn('[From:', fromId, '] Other opponent\'s candidate Skip : ', message);
			}
			break;

		default:
			console.warn('Message not Processed [messageType:' + messageType + ']', message);
			break;
	}
}

let onfailure = function(error) {
	console.log('doOffer onfailure');
	vivar.trigger('Error', [error]);
};


function checkInit() {
	return vivar && vivar.config;
}
// 임시용
function callHistoryMemberModel(members) {
	return members.map((member) => {
		return {
			id: member.id,
			extra: JSON.stringify(member)
		};
	})
}

function doOffer() {
	let rtcConnectionOfferQueue = RTCConnectionManager.offerQueue;
	console.log('RTCConnectionManager.offerQueue ---- ', RTCConnectionManager.offerQueue);

	while (rtcConnectionOfferQueue.length > 0) {
		let nextOfferId = RTCConnectionManager.getNextOfferCycle();
		let rtcConnection = RTCConnectionManager.getConnection(nextOfferId);

		if (rtcConnection) {
			rtcConnection.addLocalStream(RTCConnectionManager.getLocalStream());

			rtcConnection.createOffer((offerSDP) => {
				let arr = offerSDP.sdp.split('\r\n');
				arr.forEach((str, i) => {
					if (/^a=fmtp:\d*/.test(str)) {
						arr[i] = str + ';x-google-max-bitrate=2500';
					} else if (/^a=mid:(1|video)/.test(str)) {
						arr[i] += '\r\nb=AS:2500';
					}
				});
				offerSDP = new RTCSessionDescription({
					type: 'offer',
					sdp: arr.join('\r\n'),
				});

				rtcConnection.setLocalDescription(offerSDP).then(function() {
					wsService.sendMessageToMember(rtcConnection.remoteUserId, 'sdp', JSON.stringify(offerSDP), Date.now());
					console.log('[to:', rtcConnection.remoteUserId, '] offer SDP send : ', offerSDP);
				});
			}, onfailure, sdpConstraints)
		}
	}
}

function doAnswer(rtcConnection) {
	rtcConnection.createAnswer((answerSDP) => {
		let arr = answerSDP.sdp.split('\r\n');
		arr.forEach((str, i) => {
			if (/^a=fmtp:\d*/.test(str)) {
				arr[i] = str + ';x-google-max-bitrate=2500';
			} else if (/^a=mid:(1|video)/.test(str)) {
				arr[i] += '\r\nb=AS:2500';
			}
		});
		answerSDP = new RTCSessionDescription({
			type: 'answer',
			sdp: arr.join('\r\n'),
		});
		rtcConnection.setLocalDescription(answerSDP).then(function() {
			wsService.sendMessageToMember(rtcConnection.remoteUserId, 'sdp', JSON.stringify(answerSDP), Date.now());
			console.log('[to:', rtcConnection.remoteUserId, '] Answer SDP send : ', answerSDP);
		});
	}, onfailure, sdpConstraints);
}

function createPeerConnection(rtcConnection) {

	let iceServers = RTCConnectionManager.getIceServer();

	if (!iceServers) { // iceServer가 없으면
		console.warn('createPeerConnection -- IceServer not ready')
		return;
	}
	let peer = new RTCPeerConnection(iceServers);
	peer.ontrack = function(event) {
		console.log('on Track call back', event)
		rtcConnection.addRemoteStream(event.streams[0])
		// let video = document.createElement('video');
		// video.srcObject = event.streams[0];
		// video.autoPlay = true;
		// document.body.appendChild(video)
	};

	peer.onremovetrack = function() {
		console.log('on Remove Track call back', arguments)
	};
	peer.oniceconnectionstatechange = function() {
		console.log('oniceconnectionstatechange : ', peer.iceConnectionState);
		if (peer.iceConnectionState === 'failed' || peer.iceConnectionState === 'closed') {
			rtcConnection.callState = peer.iceConnectionState;
			vivar.trigger('UpdateConnectionState', [rtcConnection]);
		} else if (peer.iceConnectionState === 'disconnected') {
			rtcConnection.callState = 'disconnected';
			vivar.trigger('UpdateConnectionState', [rtcConnection]);
		} else if (peer.iceConnectionState === 'connected') {
			rtcConnection.callState = 'connected';
			vivar.trigger('CallConnected', [RTCConnectionManager.getMember(rtcConnection.remoteUserId), RTCConnectionManager.getMembers(), rtcConnection.peerClient.remoteVideo]);
		}

	};

	peer.onnegotiationneeded = function(event) {
		console.log('onnegotiationneeded', event);
		// doOffer();  //재 Offer  //TODO 받은 쪽에서 다시 재 Answer 해 주어야 한다고 하는데.. 활성화 시키면 중간에 에러 나고, datachnnel도 이상해짐..
	};

	peer.onsignalingstatechange = function(event) {
		console.log('onsignalingstatechange', event);
	};

	peer.onicecandidate = function(event) {

		var candidate = event.candidate;
		if (candidate && vivar.config.p2p) {
			wsService.sendMessageToMember(rtcConnection.remoteUserId, 'candidate', JSON.stringify(candidate), Date.now());
			console.log('[to:', rtcConnection.remoteUserId, '] candidate send : ', candidate);
		} else if (candidate && !vivar.config.p2p) {
			if (candidate.candidate.includes('typ relay')) {
				wsService.sendMessageToMember(rtcConnection.remoteUserId, 'candidate', JSON.stringify(candidate), Date.now());
				console.log('[to:', rtcConnection.remoteUserId, '] candidate send : ', candidate);
			}
		} else {
			console.log('candidate all gathering ---- ', candidate);
		}
	};

	peer.receiveMessageDataChannel = peer.createDataChannel('MessageChannel', dataChannelOptions);
	peer.receiveMessageDataChannel.onmessage = messageService.handleRemoteDataChannelOnMessage.bind(messageService);
	peer.receiveMessageDataChannel.onopen = function(e) {
		console.log('receiveMessageDataChannel onopen', e);
	};
	peer.receiveMessageDataChannel.onclose = function(e) {
		console.log('receiveMessageDataChannel onclose', e);
	};
	peer.receiveMessageDataChannel.onerror = function(e) {
		console.error('receiveMessageDataChannel onerror', e);
	};

	peer.receiveFileDataChannel = peer.createDataChannel('FileTransportChannel', dataChannelOptions);
	peer.receiveFileDataChannel.binaryType = 'arraybuffer';
	peer.receiveFileDataChannel.onmessage = function(e) {
		let fileReceiver = fileManager.receiveFileOnDataChannel(e);
		if(fileReceiver.state === 'init') {
			console.log('ReceiveFileStart')
			vivar.trigger('ReceiveFileStart', [fileReceiver.uuid, rtcConnection.remoteUserId, fileReceiver.meta.name, fileReceiver.meta.mimeType]);
		}
		fileReceiver.onProgress = function(cSize, fileSize) {
			let percent = Math.floor((cSize / fileSize) * 100);
			console.log('receive File onProgress - ', percent)
			if (percent % 5 === 0 ) {
				vivar.trigger('ReceiveFileOnProgress', [fileReceiver.uuid, rtcConnection.remoteUserId, fileReceiver.meta.name, fileReceiver.meta.mimeType, percent]);
			}
		}
		fileReceiver.onCompleted = function() {
			console.log('[' + fileReceiver.id + '] 파일 전송 완료');
			fileManager.clearAll();
			vivar.trigger('ReceiveFileComplete', [fileReceiver.uuid, rtcConnection.remoteUserId, fileReceiver.meta.name, fileReceiver.meta.mimeType, fileReceiver.getByteArray()]);
		}
		fileReceiver.onError = function (id) {
			fileManager.removeReceiver(id);
			vivar.trigger('ReceiveFileFail', [fileReceiver.uuid, rtcConnection.remoteUserId, fileReceiver.meta.name, fileReceiver.meta.mimeType])
		}
	}
	peer.receiveFileDataChannel.onopen = function(e) {
		console.log('receiveFileDataChannel onopen', e);
	};
	peer.receiveFileDataChannel.onclose = function(e) {
		console.log('receiveFileDataChannel onclose', e);
	};
	peer.receiveFileDataChannel.onerror = function(e) {
		console.error('receiveFileDataChannel onerror', e);
	};

	peer.receivePoseMatrixChannel = peer.createDataChannel('PoseMatrixChannel', dataChannelOptions_poseMatrix);
	peer.receivePoseMatrixChannel.onmessage = messageService.handleRemoteDataChannelOnMessage.bind(messageService);
	peer.receivePoseMatrixChannel.onopen = function(event) {
		// console.log('receivePoseMatrixChannel onopen', e);
	};
	peer.receivePoseMatrixChannel.onclose = function(e) {
		// console.log('receivePoseMatrixChannel onclose', e);
	};
	peer.receivePoseMatrixChannel.onerror = function(e) {
		console.error('receivePoseMatrixChannel onerror', e);
	};

	peer.ondatachannel = function(event) {
		sendDataChannelCallBack(event, rtcConnection); // 상대로부터 받은 데이터 채널
	};

	/*// 초당 대역폭(sent / received) 콘솔
	var prevReportSent = null, prevReportRecieved = null;
	var t = setInterval(function() {
		if (!peer) {
			prevReportSent = null;
			prevReportRecieved = null;
			return;
		}
		peer.getStats(null).then(reporter => {
			reporter.forEach(report => {
				if(report.id.includes('RTCOutboundRTPVideoStream')) {
					if (report.type === 'outbound-rtp' && report.mediaType === 'video') {
						if (prevReportSent) {
							let bps = ((report.bytesSent - prevReportSent.bytesSent)/(report.timestamp - prevReportSent.timestamp)).toFixed(1);
							if(bps > 0) {
								// console.log('send', report);
								console.log('send: ', bps + ' bps');
							}
						}
						if(report.bytesSent != 0) prevReportSent = report;
						else prevReportSent = null;
					}
				}
				if(report.id.includes('RTCInboundRTPVideoStream')) {
					if (report.mediaType === 'video') {
						if (prevReportRecieved) {
							let bps = ((report.bytesReceived - prevReportRecieved.bytesReceived) / (report.timestamp - prevReportRecieved.timestamp)).toFixed(1);
							if(bps > 0) {
								// console.log('received', report);
								console.log('received: ', bps + ' bps');
							}
						}
						if (report.bytesReceived != 0) prevReportRecieved = report;
						else prevReportRecieved = null;
					}
				}
			});
		});
	}, 1000);*/

	return peer;
}


function sendDataChannelCallBack(event, rtcConnection) {
	//TODO 이부분 리팩토링 필요
	console.log('sendDataChannelCallBack:', event.channel, event.channel.label);
	if (event.channel.label === 'MessageChannel') {
		rtcConnection.sendMessageDataChannel = event.channel;

		rtcConnection.sendMessageDataChannel.onmessage = function(e) {
			console.log('sendMessageDataChannel onmessage', e);
		};
		rtcConnection.sendMessageDataChannel.onopen = function(e) {
			vivar.trigger('SendMessageDataChannel', [rtcConnection.remoteUserId]);
			console.log('sendMessageDataChannel onopen', e);
		};
		rtcConnection.sendMessageDataChannel.onclose = function(e) {
			console.log('sendMessageDataChannel onclose', e);
		};
		rtcConnection.sendMessageDataChannel.onerror = function(e) {
			console.error('sendMessageDataChannel onerror', e);
		};
	} else if (event.channel.label === 'FileTransportChannel') {
		rtcConnection.sendFileDataChannel = event.channel;

		rtcConnection.sendFileDataChannel.onmessage = function(e) {
			console.log('sendFileDataChannel onmessage', e);
		};
		rtcConnection.sendFileDataChannel.onopen = function(e) {
			console.log('sendFileDataChannel onopen', e);
		};
		rtcConnection.sendFileDataChannel.onclose = function(e) {
			console.log('sendFileDataChannel onclose', e);
		};
		rtcConnection.sendFileDataChannel.onerror = function(e) {
			console.error('sendFileDataChannel onerror', e);
		};
	} else if (event.channel.label === 'PoseMatrixChannel') {
		rtcConnection.sendPoseMatrixDataChannel = event.channel;

		rtcConnection.sendPoseMatrixDataChannel.onmessage = function(e) {
			console.log('sendPoseMatrixDataChannel onmessage', e);
		};
		rtcConnection.sendPoseMatrixDataChannel.onopen = function(e) {
			vivar.trigger('sendPoseMatrixDataChannel', [rtcConnection.remoteUserId]);
			console.log('sendPoseMatrixDataChannel onopen', e);
		};
		rtcConnection.sendPoseMatrixDataChannel.onclose = function(e) {
			console.log('sendPoseMatrixDataChannel onclose', e);
		};
		rtcConnection.sendPoseMatrixDataChannel.onerror = function(e) {
			console.error('sendPoseMatrixDataChannel onerror', e);
		};
	}
}


function createDcVivarMessage(content) {
	console.log('message 입니다.', JSON.stringify(content));
	return messageService.createDcMessage(helper.guid(), null, 'application/mint', JSON.stringify(content), 'chunked', 0, true);
}

function initCallConfig() {
	RTCConnectionManager.init();
	fileManager.clearAll(); // 파일 매니저 초기화
	vivar.me.state = 'alive';
	wsService.postState();
	vivar.trigger('UpdateState', [vivar.me]);
}

function setIceServer(servers) {
	RTCConnectionManager.setIceServer(servers);
}



function loginFactory(memberToken, isForce) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state !== 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	if (memberToken === null || memberToken === undefined) {
		return 1005; // ERROR_VIVAR_INVALID_LOGIN_ID;
	}

	try {
		webSocket = wsService.connectWebSocketFactory(memberToken, isForce);
	} catch (error) {
		return error.response.status;
	}
	return 0;
}

function logout() {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	leaveAllGroups();
	vivar.me.state = 'offline';
	wsService.postState();
	vivar.trigger('UpdateState', [vivar.me]);

	setTimeout(() => {
		console.log('websocket close', WebSocket.OPEN);
		if (wsService.webSocket.readyState === WebSocket.OPEN) {
			wsService.close();
		}
	}, 300);
	RTCConnectionManager.init();
	return 0;
}

function joinGroup(name, key, extra) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	if (name === null || name === undefined || name.length < 4 || name.length > 32 || !(/^([a-zA-Z0-9_]+)$/i).test(name)) {
		return 1006; // ERROR_VIVAR_INVALID_GROUP_ID;
	}
	wsService.joinGroup(name, key, extra);
	return 0;
}

function getGroupMembers(groupId) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	if (!groupId) {
		return 1006; // ERROR_VIVAR_INVALID_GROUP_ID;
	}
	let group = vivar.joinGroups[groupId];
	if (!group) {
		return 1006; // ERROR_VIVAR_INVALID_GROUP_ID;
	}
	wsService.getGroupMembers(groupId, Date.now());
	return 0;
}

function getMember(memberId) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	if (!memberId) {
		return 1006; // ERROR_VIVAR_INVALID_GROUP_ID;
	}
	// TODO 멤버가 그룹 안에 있는지 체크는?

	wsService.getMember(memberId, Date.now());
	return 0;
}

function getMyExt() {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}

	wsService.getMyExt(Date.now());
	return 0;
}

function leaveGroup(groupId) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	if (groupId !== null && groupId !== undefined) {
		let group = vivar.joinGroups[groupId];
		if (!group) {
			return 1006; // ERROR_VIVAR_INVALID_GROUP_ID;
		}
		wsService.leaveGroup(groupId);
	} else {
		leaveAllGroups();
	}
	return 0;
}

function leaveAllGroups() {
	for (let groupId in vivar.joinGroups) {
		if (Object.prototype.hasOwnProperty.call(vivar.joinGroups, groupId)) {
			wsService.leaveGroup(groupId);
		}
	}
}

function changePassword(oldPassword = '', newPassword = '') {
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	wsService.changePassword(oldPassword, newPassword);
	return 0;
}

function updateGroup(groupId, name, extra) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	if (!groupId) {
		return 1006; // ERROR_VIVAR_INVALID_GROUP_ID;
	}
	if (name === null || name === undefined || name.length < 4 || name.length > 32 || !(/^([a-zA-Z0-9_]+)$/i).test(name)) {
		return 1006; // ERROR_VIVAR_INVALID_GROUP_ID;
	}
	wsService.updateGroup(groupId, name, extra);
	return 0;
}

function updateMe(me = null, extra = null, os = null, pushToken = null, modelNo = null, deviceUid = null) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	wsService.updateMe(me, extra, os, pushToken, modelNo, deviceUid);
	//wsService.updateMe(id, password, extra);
	return 0;
}

function updateProfileImg(uploadFile) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}

	wsService.updateProfileImg(uploadFile.name, uploadFile.content);
	return 0;
}

function updateStat(statId, remotId, type, startDT, endDT, durations) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}

	wsService.updateStat(statId, remotId, type, startDT, endDT, durations);

	return 0;
}

function updateMyExt({ configs = null, contacts = null, extra = null } = {}) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}

	wsService.updateMyExt(configs, contacts, extra);

	return 0;
}

function getMyGroups() {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	wsService.getMyGroups();
	return 0;
}

function getGroups() {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	httpService.getGroups(vivar.config.sign);
	return 0;
}

function getMyCallHistories() {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	wsService.getMyCallHistories();
	return 0;
}

function getMyCallHistory(myHDbId) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}
	wsService.getMyCallHistory(myHDbId);
	return 0;
}

function createMyCallHistory(type, members, extra, shareMemberId) {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}

	// 임시용 이부분 서버에서 수정해줄 필요 있음
	let memberList = callHistoryMemberModel(members);
	//
	wsService.createMyCallHistory(type, RTCConnectionManager.getId(), memberList, extra, shareMemberId);
	return 0;
}

function updateMyCallHistory(type, uuid, members = [], extra = '') {
	if (checkInit() === false) {
		return 1001; // ERROR_VIVAR_INVALID_SIGNATURE;
	}
	if (vivar.me.state === 'offline') {
		return 1003; // ERROR_VIVAR_INVALID_STATE;
	}

	let memberList = callHistoryMemberModel(members);
	wsService.updateMyCallHistory(type, uuid, memberList, extra);
	return 0;
}
// requestCall 호출.
function sendCall(members, group) {
	vivar.me.state = 'busy';
	wsService.postState();
	vivar.trigger('UpdateState', [vivar.me]);

	let groupId;
	if (!RTCConnectionManager.getId()) { // id 없으면
		groupId = helper.guid();
		RTCConnectionManager.setId(groupId);
	}
	
	if (!!members) RTCConnectionManager.setMembers(JSON.parse(JSON.stringify(members)));
	if (!!group) RTCConnectionManager.setGroup(JSON.parse(JSON.stringify(group))); // workspace 정보 초기화


	return RTCConnectionManager.setLocalVideo(mediaStreamConstraints, vivar.config.localVideo)
		.then(() => {
			members.forEach((member) => {
				if (parseInt(vivar.me.id) !== parseInt(member.id)) {
					let rtcConnection = new RTCConnection(vivar.me.id, member.id);

					RTCConnectionManager.isReadytoCallConnect = true;
					RTCConnectionManager.add(rtcConnection);

					let group = RTCConnectionManager.getGroup();
					if (group && Object.keys(group).length > 0) group.extra = JSON.stringify(group.extra);
					else group = defaultGroup;
					let message = { uuid: RTCConnectionManager.getId(), group: group, members: RTCConnectionManager.getMembers() };
					wsService.sendMessageToMember(member.id, 'requestCall', JSON.stringify(message), Date.now());
					console.log('[to:', rtcConnection.remoteUserId, '] requestCall send : ', message);
				}
			});
		})
		.catch(onfailure);
}

function inviteCall(newMembers, members) {
	if (!Array.isArray(newMembers)) {
		newMembers = [newMembers];
	}

	vivar.me.state = 'busy';
	wsService.postState();
	vivar.trigger('UpdateState', [vivar.me]);

	let groupId;
	if (!RTCConnectionManager.getId()) { // id 없으면
		groupId = helper.guid();
		RTCConnectionManager.setId(groupId);
	}

	RTCConnectionManager.setMembers(JSON.parse(JSON.stringify(members)));
	// RTCConnectionManager.isReadytoCallConnect = true;

	newMembers.forEach((member) => {
		if (parseInt(vivar.me.id) !== parseInt(member.id)) {
			let rtcConnection = new RTCConnection(vivar.me.id, member.id);
			RTCConnectionManager.add(rtcConnection);

			let group = RTCConnectionManager.getGroup();
			group.extra = JSON.stringify(group.extra);
			let message = { uuid: RTCConnectionManager.getId(), group: group, members: RTCConnectionManager.getMembers() };
			wsService.sendMessageToMember(member.id, 'inviteCall', JSON.stringify(message), Date.now());
			console.log('[to:', rtcConnection.remoteUserId, '] inviteCall send : ');
		}
	});

	return 0;
}

function acceptCall() {
	vivar.me.state = 'busy';
	wsService.postState();
	vivar.trigger('UpdateState', [vivar.me]);

	let members = RTCConnectionManager.getMembers();

	RTCConnectionManager.setLocalVideo(mediaStreamConstraints, vivar.config.localVideo)
		.then(() => {
			members.forEach((member) => {
				if (parseInt(vivar.me.id) !== parseInt(member.id)) {
					let rtcConnection = RTCConnectionManager.getConnection(member.id);
					if (rtcConnection === undefined) {
						rtcConnection = new RTCConnection(vivar.me.id, member.id);
						RTCConnectionManager.add(rtcConnection);
					}
					RTCConnectionManager.isReadytoCallConnect = true;

					let group = RTCConnectionManager.getGroup();
					if (!!group && Object.keys(group).length > 0) {
						group.extra = JSON.stringify(group.extra);
					}
					else {
						group = defaultGroup;
					}
					let message = { uuid: RTCConnectionManager.getId(), group: group, members: members };
					wsService.sendMessageToMember(member.id, 'acceptCall', JSON.stringify(message), Date.now());
					console.log('[to:', member.id, '] acceptCall send : ', message);
				}
			});

			doOffer();
		}, onfailure);
	return 0;
}

function rejectCall(reason) {
	let members = RTCConnectionManager.getMembers();

	members.forEach((member) => {
		if (parseInt(vivar.me.id) !== parseInt(member.id)) {
			let message = { uuid: RTCConnectionManager.getId(), rejectReason: reason };
			wsService.sendMessageToMember(member.id, 'rejectCall', JSON.stringify(message), Date.now());
			console.log('[to:', member.id, '] reject send : ');
		}
	});

	initCallConfig();
	vivar.trigger('CallDisconnected', [null, true]);
	return 0;
}

function cancelCall() {
	let members = RTCConnectionManager.getMembers();

	members.forEach((member) => {
		if (parseInt(vivar.me.id) !== parseInt(member.id)) {
			let message = { uuid: RTCConnectionManager.getId(), members: members };
			wsService.sendMessageToMember(member.id, 'cancelCall', JSON.stringify(message), Date.now());
			console.log('[to:', member.id, '] cancelCall send : ');
		}
	});
	initCallConfig();
	vivar.trigger('CallDisconnected', [null, true]);
	return 0;
}

async function endCall() {
	let members = RTCConnectionManager.getMembers();

	members.forEach((member) => {
		if (parseInt(vivar.me.id) !== parseInt(member.id)) {
			let message = { uuid: RTCConnectionManager.getId(), members: members };
			wsService.sendMessageToMember(member.id, 'endCall', JSON.stringify(message), Date.now());
			console.log('[to:', member.id, '] endCall send : ');
		}
	});

	vivar.trigger('CallDisconnected', [null, true]);

	let sleep = t => new Promise( r => setTimeout(r, t))
	await sleep(2000)
	initCallConfig();
	return 0;
}

function sendSignalingFailMember(memberId) {
	let members = RTCConnectionManager.getMembers();

	let member = RTCConnectionManager.getMember(memberId);
	let rtcConnection = RTCConnectionManager.getConnection(memberId);
	let isCaller = member.isCaller;

	RTCConnectionManager.removeConnection(memberId);
	if (Object.keys(RTCConnectionManager.connections).length === 0 || isCaller) {
		// 방장이거나 연결된 객체가 더이상 없을 때
		endCall().then(r => {
			console.log(r)});
	} else {
		members.forEach((member) => {
			if (parseInt(vivar.me.id) !== parseInt(member.id) && parseInt(member.id) !== parseInt(memberId)) {
				let message = { uuid: RTCConnectionManager.getId(), userId: memberId };
				wsService.sendMessageToMember(member.id, 'signalingFail', JSON.stringify(message), Date.now());
				console.log('[to:', member.id, '] signalingFail send : ');
			}
		});

		vivar.trigger('CallDisconnected', [rtcConnection, false]);
	}
	return 0;
}

function sendMessage(message) {
	if (message === null || message === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'Chat',
		data: JSON.stringify({
			messageType: 'Text',
			userId: vivar.me.id,
			text: message
		})
	};
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);
	console.log('sendMessage' + '[' + Object.keys(dcMessage).length + ']: ' + dcMessage);

	return 0;
}

function sendFile(...file) {
	if (!file) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let members = RTCConnectionManager.getMembers();

	let uploadFile = file;
	for (let i = 0; i < uploadFile.length; i++) {
		members.forEach((member) => {
			if (parseInt(vivar.me.id) !== parseInt(member.id)) {
				let rtcConnection = RTCConnectionManager.getConnection(member.id);
				if (rtcConnection === undefined) {
					console.warn('[sendFile]-- rtcConnection is not exsite');
				} else {
					let fileSender = fileManager.sendFileOnDataChannel(uploadFile[i], rtcConnection.sendFileDataChannel, onReadSlice, onReadSliceError)
					fileSender.onProgress = function(cSize, fileSize) {
						console.log('send File onProgress - ', cSize, fileSize)
					};
					fileSender.onCompleted = function() {
						console.log(uploadFile[i].name + ': 전송 완료')
					}

					function onReadSlice(sliceData, chunkSize, length, offset, isLast) {
						console.log('sendFile: ' + uploadFile[i].name + '[' + chunkSize + ']'  + ' length -' + length + ', offset - ' + offset + ' isLast - ' + isLast);
					}

					function onReadSliceError(error) {
						vivar.trigger('Error', [error]);
					}
				}
			}
		});

	}
	return 0;
}

function sendReqTrackingResult(workerId) {
	const content = {
		type: 'Tracking',
		data: JSON.stringify({
			messageType: 'ReqTrackingResult',
		})
	};
	let dcMessage = createDcVivarMessage(content);

	let rtcConnection = RTCConnectionManager.getConnection(workerId);
	if (rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		console.log('ReqTrackingResult: ', dcMessage);
	}

	return 0;
}

function sendMemberLiveInfoReq(userId) {
	const content = {
		type: 'Misc',
		data: JSON.stringify({
			messageType: 'MemberLiveInfoReq',
			userId: userId
		})
	}
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);

	return 0;
}

function sendMemberLiveInfoRes() {
	const content = {
		type: 'Misc',
		data: JSON.stringify({
			messageType: 'MemberLiveInfoRes',
			userId: vivar.me.id,
			os: "web",
			prfImgURL: vivar.me.prfImgURL
		})
	}

	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);
	return 0;
}

function sendARPrepare(workerId) {
	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'ArPrepare'
		})
	};
	let dcMessage = createDcVivarMessage(content);

	let rtcConnection = RTCConnectionManager.getConnection(workerId);
	if (rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		console.log('sendARPrepare: ', dcMessage);
	}
	return 0;
}

function sendARDrawingReq(drawingObject) {
	if (drawingObject === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'DrawingReq',
			userId: vivar.me.id,
			objectId: drawingObject.id,
			stroke: drawingObject.stroke,
			prevPose: drawingObject.prevPose
		})
	};

	let dcMessage = createDcVivarMessage(content);

	let worker = RTCConnectionManager.getWorker();

	if (parseInt(vivar.me.id) === parseInt(worker.id)) {
		// 본인이 화면 공유자면
		vivar.trigger('ReceiveARDrawingReq', [JSON.parse(content.data)]);
	} else {
		// 화면 공유자에게 request
		let rtcConnection = RTCConnectionManager.getConnection(worker.id);
		if (rtcConnection.sendMessageDataChannel) {
			rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		}
	}

	console.log('sendARDrawingReq', dcMessage);
	return 0;
}

function sendARDrawingAdd(data, anchorPose) {
	if (data === undefined || anchorPose === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'DrawingAdd',
			userId: data.userId,
			objectId: data.objectId,
			stroke: data.stroke,
			anchorPose
		})
	};
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);
	console.log('sendARDrawingAdd', dcMessage);
	return 0;
}
function sendARStickerReq(stickerObject) {
	if (stickerObject === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'StickerReq',
			userId: vivar.me.id,
			objectId: stickerObject.id,
			sticker: stickerObject.sticker,
			prevPose: stickerObject.prevPose
		})
	};

	let dcMessage = createDcVivarMessage(content);

	let worker = RTCConnectionManager.getWorker();

	if (parseInt(vivar.me.id) === parseInt(worker.id)) {
		// 본인이 화면 공유자면
		vivar.trigger('ReceiveARDrawingReq', [JSON.parse(content.data)]);
	} else {
		// 화면 공유자에게 request
		let rtcConnection = RTCConnectionManager.getConnection(worker.id);
		if (rtcConnection.sendMessageDataChannel) {
			rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		}
	}

	console.log('sendARSticker', dcMessage);
	return 0;
}

function sendARStickerAdd(data, anchorPose) {
	if (data === undefined || anchorPose === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'StickerAdd',
			userId: data.userId,
			objectId: data.objectId,
			sticker: data.sticker,
			anchorPose
		})
	};

	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);
	console.log('sendARStickerAdd', dcMessage);
	return 0;
}

function sendARStickerAddToMember(memberId,data) {
	if (data === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'StickerAdd',
			userId: data.userId,
			objectId: data.objectId,
			sticker: data.sticker,
			anchorPose: data.anchorPose
		})
	};
	let dcMessage = createDcVivarMessage(content);
	let rtcConnection = RTCConnectionManager.getConnection(memberId);
	if (rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
	}
	console.log('sendARStickerAddToMember', dcMessage);
	return 0;
}

function sendARDrawingAddToMember(memberId, data) {
	if (data === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'DrawingAdd',
			userId: data.userId,
			objectId: data.objectId,
			stroke: data.stroke,
			anchorPose: data.anchorPose
		})
	};
	let dcMessage = createDcVivarMessage(content);
	let rtcConnection = RTCConnectionManager.getConnection(memberId);
	if (rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
	}
	console.log('sendARDrawingAddToMember', dcMessage);
	return 0;
}

function sendARUndo() {
	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			userId: vivar.me.id,
			messageType: 'InteractionArUndo'
		})
	};
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);

	console.log('sendARUndo', dcMessage);
	return 0;
}

function sendARRemoveReq() {
	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			userId: vivar.me.id,
			messageType: 'InteractionArRemoveReq'
		})
	};
	let dcMessage = createDcVivarMessage(content);

	let worker = RTCConnectionManager.getWorker();

	if (parseInt(vivar.me.id) === parseInt(worker.id)) {
		// 본인이 화면 공유자면
		vivar.trigger('ReceiveARRemoveReq', [JSON.parse(content.data)]);
	} else {
		// 화면 공유자에게 request
		let rtcConnection = RTCConnectionManager.getConnection(worker.id);
		if (rtcConnection.sendMessageDataChannel) {
			rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		}
	}

	console.log('sendARRemoveReq', dcMessage);

	return 0;
}

function sendARRemove(data) {
	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			userId: data.userId,
			messageType: 'InteractionArRemove'
		})
	};

	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);

	console.log('sendARRemove', dcMessage);
	return 0;
}

function sendARRemoveAllReq() {
	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			userId: vivar.me.id,
			messageType: 'InteractionArRemoveAllReq'
		})
	};
	let dcMessage = createDcVivarMessage(content);

	let worker = RTCConnectionManager.getWorker();

	if (parseInt(vivar.me.id) === parseInt(worker.id)) {
		// 본인이 화면 공유자면
		vivar.trigger('ReceiveARDrawingRemoveAllReq', [JSON.parse(content.data)]);
	} else {
		// 화면 공유자에게 request
		let rtcConnection = RTCConnectionManager.getConnection(worker.id);
		if (rtcConnection.sendMessageDataChannel) {
			rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		}
	}

	console.log('sendARDrawingRemoveAllReq', dcMessage);

	return 0;
}

function sendARRemoveAll() {
	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'InteractionArRemoveAll'
		})
	};
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);

	console.log('sendARRemoveAll', dcMessage);

	return 0;
}

function sendARColorChange(color) {
	if (color === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'PersonalColorChange',
			userId: color.userId,
			personalColor: color.personalColor //{colorName, color}
		})
	};
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);

	console.log('sendARColorChange', dcMessage);
	return 0;
}

function sendMyARColor(color) {
	if (color === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'PersonalColorChange',
			userId: color.userId,
			personalColor: color.personalColor //{colorName, color}
		})
	};
	let dcMessage = createDcVivarMessage(content);

	RTCConnectionManager.notify(dcMessage);

	console.log('sendMyARColor', dcMessage);
	return 0;
}

function sendARInitColor(userId, color) {
	if (color === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			messageType: 'PersonalColorChange',
			userId: userId,
			personalColor: color //{colorName, color}
		})
	};

	let dcMessage = createDcVivarMessage(content);

	let rtcConnection = RTCConnectionManager.getConnection(userId);
	if (rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		console.log('sendARInitColor: ', dcMessage);
	}
	return 0;
}

function sendCameraFrameSizeReq() {
	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			userId: vivar.me.id,
			messageType: 'CameraFrameSizeReq',
		})
	};

	let dcMessage = createDcVivarMessage(content);

	let worker = RTCConnectionManager.getWorker();

	if (parseInt(vivar.me.id) === parseInt(worker.id)) {
		// 본인이 화면 공유자면
		vivar.trigger('ReceiveCameraFrameSizeReq', [JSON.parse(content.data)]);
	} else {
		// 화면 공유자에게 request
		let rtcConnection = RTCConnectionManager.getConnection(worker.id);
		if (rtcConnection.sendMessageDataChannel) {
			rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		}
	}
	console.log('sendCameraFrameSizeReq', dcMessage);
	return 0;
}

function sendCameraFrameSize(data) {
	if (data === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionAr',
		data: JSON.stringify({
			userId: data.userId,
			messageType: 'CameraFrameSize',
			size: data.size, // width, height
			arSupported: data.arSupported
		})
	};

	let dcMessage = createDcVivarMessage(content);


	let worker = RTCConnectionManager.getWorker();

	if (parseInt(vivar.me.id) === parseInt(worker.id)) {
		// 본인이 화면 공유자면
		vivar.trigger('ReceiveCameraFrameSize', [JSON.parse(content.data)]);
	}
	// sendCameraFrameReq 요청한 상대에게
	let rtcConnection = RTCConnectionManager.getConnection(data.userId);
	if (!!rtcConnection && rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
	}
	console.log('sendCameraFrameSize', dcMessage);
	return 0;
}

function sendScreenDrawingCanvasPoint(drawingObject) {
	if (drawingObject === undefined) {
		return 4002; // ERROR_RTC_SEND_DATA
	}

	let content = {
		type: 'InteractionPCScreen',
		data: JSON.stringify({
			messageType: 'PCScreenDrawingCanvasPoint',
			userId: vivar.me.id,
			objectId: drawingObject.id,
			canvas: drawingObject.canvas,
			stroke: drawingObject.stroke,
			extra: drawingObject.extra
		})
	};

	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);

	console.log('sendPCScreenDrawingCanvasPoint', dcMessage);
	return 0;
}

function enableVideo(value) {
	if (value === null || value === undefined) {
		value = true;
	}
	if (RTCConnectionManager.localStream && RTCConnectionManager.localStream.getVideoTracks()) {
		RTCConnectionManager.localStream.getVideoTracks()[0].enabled = value;
	}

	let content = {
		type: 'State',
		data: JSON.stringify({
			target: 'Video',
			messageType: value ? 'Enable' : 'Disable'
		})
	};
	let dcMessage = createDcVivarMessage(content);
	// sendData(JSON.stringify(dcMessage));
	console.log('send enableVideo: ', dcMessage);

	return 0;
}

function enableAudio(value) {
	if (value === null || value === undefined) {
		value = true;
	}
	if (RTCConnectionManager.localStream && RTCConnectionManager.localStream.getAudioTracks()) {
		console.log(RTCConnectionManager.localStream.getTracks())
		RTCConnectionManager.localStream.getAudioTracks()[0].enabled = value;
	}

	let content = {
		type: 'State',
		data: JSON.stringify({
			messageType: 'Audio',
			state: value ? 'Enable' : 'Disable',
			userId: vivar.me.id
		})
	};
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);
	console.log('send enableAudio: ', dcMessage);

	return 0;
}

function enableRecording(value) {
	if (value === null || value === undefined) {
		value = true;
	}
	let content = {
		type: 'State',
		data: JSON.stringify({
			messageType: 'Recording',
			state: value ? 'Enable' : 'Disable',
			userId: vivar.me.id
		})
	};

	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);
	console.log('send enableRecording: ', dcMessage);

	return 0;
}

async function requestPCScreenShare() {
	const videoConstraint = {
		video: {
			displaySurface: 'window',
			width: {max:1280},
			height: {max:720},
			aspectRatio: {max:16/9},
		}
	}
	function getDisplayMediaError(error) {
		if (location.protocol === 'http:') {
			alert('Please test this WebRTC experiment on HTTPS.');
		} else {

			// alert(error.toString());
		}
		let transactor = RTCConnectionManager.getSwitchingTransactor();

		if (!transactor) {
			transactor = RTCConnectionManager.createSwitchingTransactor(RTCConnectionManager.getWorker().id);
		}
		let errorMessage = 'UserReject';
		sendSwitchScreenShareFail(transactor, errorMessage);
		throw error;
	}
	if (navigator.getDisplayMedia || navigator.mediaDevices.getDisplayMedia) {
		async function onGettingSteam(stream) {
			if (RTCConnectionManager.localStream) {
				let tracks = RTCConnectionManager.localStream.getTracks();
				for (let i = 0; i < tracks.length; i++) {
					tracks[i].stop();
				}
			}
			RTCConnectionManager.localStream = null;
			RTCConnectionManager.localStream = stream;
			console.log('RTCConnectionManager', RTCConnectionManager.localStream.getTracks())
			let connections = RTCConnectionManager.connections;

			try {
				Object.keys(connections).forEach(async(id) => {
					await connections[id].addLocalPCStream(stream);
				});
			} catch (error) {
				alert(error)
			}

			return stream;
		}

		try {
			let stream, audio;
			if (navigator.mediaDevices.getDisplayMedia) {
				stream = await navigator.mediaDevices.getDisplayMedia(videoConstraint).then(async stream=>{
					//너비_높이 체크
					const videoTrack = stream.getVideoTracks()[0];
					let videoSetting = videoTrack.getSettings();
					let width = videoSetting.width, height = videoSetting.height;
					let limitW = height*(16/9), limitH = width*(9/16);
					if (height > limitH) {
						height = limitH;
					}
					else if (width > limitW) {
						width = limitW;
					}

					const videoConstraint = {
						displaySurface: 'window',
						width: {max:width},
						height: {max:height},
						aspectRatio: {max:16/9},
					}

					async function apply(c) {
						await videoTrack.applyConstraints(Object.assign(videoTrack.getSettings(), c));
					}
					apply(videoConstraint);
					return stream;
				});
				audio = await navigator.mediaDevices.getUserMedia({ audio: true });
				stream.addTrack(audio.getTracks()[0]);
			} else if (navigator.getDisplayMedia) {
				stream = await navigator.getDisplayMedia(videoConstraint);
				audio = await navigator.getUserMedia({ audio: true });
				stream.addTrack(audio.getTracks()[0]);
			}
			return await onGettingSteam(stream);

		} catch (err) {
			getDisplayMediaError(err)
		}

	} else {
		if (DetectRTC.browser.name === 'Chrome') {
			if (DetectRTC.browser.version === 71) {
				alert('Please enable "Experimental WebPlatform" flag via chrome://flags.');
			} else if (DetectRTC.browser.version < 71) {
				alert('Please upgrade your Chrome browser.');
			} else {
				alert('Please make sure that you are not using Chrome on iOS.');
			}
		}
		if (DetectRTC.browser.name === 'Firefox') {
			alert('Please upgrade your Firefox browser.');
		}
		if (DetectRTC.browser.name === 'Edge') {
			alert('Please upgrade your Edge browser.');
		}
		if (DetectRTC.browser.name === 'Safari') {
			alert('Safari does NOT supports getDisplayMedia API yet.');
		}
	}
}
async function requestRecordingPCScreen() {
	function getDisplayMediaError(error) {
		if (location.protocol === 'http:') {
			alert('Please test this WebRTC experiment on HTTPS.');
		} else {
			alert(error.toString());
		}
		throw error;
	}
	if (navigator.getDisplayMedia || navigator.mediaDevices.getDisplayMedia) {
		async function onGettingSteam(stream) {
			RTCConnectionManager.recordingScreenStream = stream;
			return stream;
		}
		try {
			let stream;
			if (navigator.mediaDevices.getDisplayMedia) {
				stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });

			} else if (navigator.getDisplayMedia) {
				stream = await navigator.getDisplayMedia({ video: true, audio: true });
			}
			return await onGettingSteam(stream);
		} catch (err) {
			getDisplayMediaError(err)
		}

	} else {
		if (DetectRTC.browser.name === 'Chrome') {
			if (DetectRTC.browser.version === 71) {
				alert('Please enable "Experimental WebPlatform" flag via chrome://flags.');
			} else if (DetectRTC.browser.version < 71) {
				alert('Please upgrade your Chrome browser.');
			} else {
				alert('Please make sure that you are not using Chrome on iOS.');
			}
		}
		if (DetectRTC.browser.name === 'Firefox') {
			alert('Please upgrade your Firefox browser.');
		}
		if (DetectRTC.browser.name === 'Edge') {
			alert('Please upgrade your Edge browser.');
		}
		if (DetectRTC.browser.name === 'Safari') {
			alert('Safari does NOT supports getDisplayMedia API yet.');
		}
	}
}
async function sendSwitchScreenShareReq(userId) {
	console.log('sendSwitchScreenShareReq');

	const transactor = RTCConnectionManager.createSwitchingTransactor(userId);

	// 새로운 화면 공유자가 Caller(방장)인 경우도 예외 처리
	let content = {
		type: 'ScreenShare',
		data: JSON.stringify({
			messageType: 'SwitchReq',
			transactionId: transactor.transactionId,
			userId: transactor.userId.toString()
		})
	};
	let dcMessage = createDcVivarMessage(content);
	RTCConnectionManager.notify(dcMessage);

	// Caller가 자기 자신에게 화면 공유자 변경시
	let caller = RTCConnectionManager.getCaller();
	if (parseInt(caller.id) === parseInt(userId)) {
		console.log('방장에게 보냄');
		try {
			await requestPCScreenShare();
			sendSwitchScreenShareState();
			// RTCConnectionManager.localStream.getVideoTracks()[0].enabled = true;
			return transactor;

		} catch (e) {
			console.warn(e)
			throw e;
		}
	}
	return transactor;
}

//  responseSwitchScreenShare
async function sendSwitchScreenShareRes(transactor) {
	// Caller에게 전송할 내용
	let content = {
		type: 'ScreenShare',
		data: JSON.stringify({
			messageType: 'SwitchRes',
			transactionId: transactor.transactionId,
			userId: transactor.userId.toString(),
			errorMessage: transactor.errorMessage || null
		})
	};

	let caller = RTCConnectionManager.getCaller();
	let dcMessage = createDcVivarMessage(content);
	let rtcConnection = RTCConnectionManager.getConnection(caller.id);
	if (rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		console.log('sendSwitchScreenShareRes: ', dcMessage);
	}
	// UserReject : 신규 화면 공유자가 거부
	// NoResponse : 제한시간(ex 30s)내 응답 없음
	if(transactor.errorMessage !== 'UserReject' && transactor.errorMessage !== 'NoResponse')
		try {
			await requestPCScreenShare();
			sendSwitchScreenShareComplete(transactor);
			// RTCConnectionManager.localStream.getVideoTracks()[0].enabled = true;
		} catch (e) {
			console.warn(e)
		}
}

// new ScreenSharer가 Caller에게 전달
function sendSwitchScreenShareComplete(transactor) {
	let content = {
		type: 'ScreenShare',
		data: JSON.stringify({
			messageType: 'SwitchComplete',
			transactionId: transactor.transactionId,
			userId: transactor.userId.toString()
		})
	}
	let dcMessage = createDcVivarMessage(content);

	let caller = RTCConnectionManager.getCaller();
	let rtcConnection = RTCConnectionManager.getConnection(caller.id);
	if (rtcConnection.sendMessageDataChannel) {
		rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
		console.log('sendSwitchScreenShareComplete: ', dcMessage);
	}
	return 0;
}

// fail은 Caller가 모든 멤버에게 fail 전송.
function sendSwitchScreenShareFail(transactor, errorMessage) {
	let content = {
		type: 'ScreenShare',
		data: JSON.stringify({
			messageType: 'SwitchFail',
			transactionId: transactor.transactionId,
			userId: transactor.userId.toString(),
			errorMessage: errorMessage || null
		})
	}
	let dcMessage = createDcVivarMessage(content);

	RTCConnectionManager.notify(dcMessage);

	console.log('sendSwitchScreenShareFail', dcMessage);
}

// 우선 State 전부 broadcasting
function sendSwitchScreenShareState(newUserId) {

	let transactor = RTCConnectionManager.getSwitchingTransactor();

	if (!transactor) {
		transactor = RTCConnectionManager.createSwitchingTransactor(RTCConnectionManager.getWorker().id);
	}
	let content = {
		type: 'ScreenShare',
		data: JSON.stringify({
			messageType: 'State',
			transactionId: transactor.oldTransactionId || null,
			userId: transactor.userId.toString()
		})
	};

	// Transactor 초기화
	transactor.init();

	let dcMessage = createDcVivarMessage(content);
	if (newUserId) {
		// 새로운 user 초대시(invite 됬을 경우) state 전달.
		let rtcConnection = RTCConnectionManager.getConnection(newUserId);
		if (rtcConnection) {
			// if(RTCConnectionManager.localPCStream) {
			//     rtcConnection.addLocalPCStream(RTCConnectionManager.getLocalPCStream());
			// }
			if (rtcConnection.sendMessageDataChannel) {
				rtcConnection.sendMessageDataChannel.send(JSON.stringify(dcMessage));
				console.log('sendSwitchScreenShareState: ', dcMessage);
			}
		} else {
			console.warn(`[sendSwitchScreenShareState to Invited User ${newUserId}] - RTCConnection not exist`)
		}
	} else {
		// complete시 새로운 state를 브로드 캐스팅.
		RTCConnectionManager.notify(dcMessage);
		console.log('sendSwitchScreenShareState', dcMessage);
	}
	return transactor;
}

function getMemberStream(userId) {
	if(RTCConnectionManager.localStream.getVideoTracks()[0].readyState === 'live') RTCConnectionManager.localStream.getVideoTracks()[0].stop();
	let rtcConnection = RTCConnectionManager.getConnection(parseInt(userId));
	return rtcConnection.getRemoteStream();
}
function getAudioTracksAll() {
	return RTCConnectionManager.getAudioTracksAll();
}
function getAudioTrack(userId) {
	return RTCConnectionManager.getAudioTrack(parseInt(userId));
}
function getLocalAudioTrack() {
	return RTCConnectionManager.getLocalAudioTrack();
}

function setWorker(userId) {
	// 새로운 Worker 등록
	RTCConnectionManager.setWorker(userId);
}

function getWorker() {
	return RTCConnectionManager.getWorker();
}

function getLocalPCStream() {
	// return RTCConnectionManager.getLocalPCStream();
	return RTCConnectionManager.getLocalStream();
}

function getRTCConnections() {
	return RTCConnectionManager.connections;
}
function getCallUId() {
	return RTCConnectionManager.getId();
}
module.exports = {
	setVivar: function(paramVivar) {
		vivar = paramVivar;
		messageService.setVivar(vivar);

		vivar.loginFactory = loginFactory;
		vivar.logout = logout;
		vivar.changePassword = changePassword;
		vivar.joinGroup = joinGroup;
		vivar.leaveGroup = leaveGroup;
		vivar.updateGroup = updateGroup;
		vivar.updateMe = updateMe;
		vivar.updateMyExt = updateMyExt;
		vivar.getGroupMembers = getGroupMembers;
		vivar.getMember = getMember;
		vivar.getMyExt = getMyExt;
		vivar.getMyGroups = getMyGroups;
		vivar.getGroups = getGroups;
		vivar.sendCall = sendCall;
		vivar.inviteCall = inviteCall;
		vivar.cancelCall = cancelCall;
		vivar.endCall = endCall;
		vivar.acceptCall = acceptCall;
		vivar.rejectCall = rejectCall;
		vivar.sendSignalingFailMember = sendSignalingFailMember;
		vivar.sendMessage = sendMessage;
		vivar.sendFile = sendFile;
		vivar.sendCameraFrameSizeReq = sendCameraFrameSizeReq;
		vivar.sendCameraFrameSize = sendCameraFrameSize;
		vivar.sendReqTrackingResult = sendReqTrackingResult;
		vivar.sendMemberLiveInfoReq = sendMemberLiveInfoReq;
		vivar.sendMemberLiveInfoRes = sendMemberLiveInfoRes;
		vivar.sendARDrawingReq = sendARDrawingReq;
		vivar.sendARDrawingAdd = sendARDrawingAdd;
		vivar.sendARDrawingAddToMember = sendARDrawingAddToMember;
		vivar.sendARStickerReq = sendARStickerReq;
		vivar.sendARStickerAdd = sendARStickerAdd;
		vivar.sendARStickerAddToMember = sendARStickerAddToMember;
		vivar.sendARPrepare = sendARPrepare;
		vivar.sendARUndo = sendARUndo;
		vivar.sendARRemoveReq = sendARRemoveReq;
		vivar.sendARRemove = sendARRemove;
		vivar.sendARRemoveAllReq = sendARRemoveAllReq;
		vivar.sendARRemoveAll = sendARRemoveAll;
		vivar.sendScreenDrawingCanvasPoint = sendScreenDrawingCanvasPoint;
		vivar.sendARColorChange = sendARColorChange;
		vivar.sendARInitColor = sendARInitColor;
		vivar.sendMyARColor = sendMyARColor;
		vivar.enableVideo = enableVideo;
		vivar.enableAudio = enableAudio;
		vivar.enableRecording = enableRecording;
		vivar.createMyCallHistory = createMyCallHistory;
		vivar.updateMyCallHistory = updateMyCallHistory;
		vivar.getMyCallHistories = getMyCallHistories;
		vivar.getMyCallHistory = getMyCallHistory;
		vivar.sendSwitchScreenShareReq = sendSwitchScreenShareReq;
		vivar.sendSwitchScreenShareRes = sendSwitchScreenShareRes;
		vivar.sendSwitchScreenShareComplete = sendSwitchScreenShareComplete;
		vivar.sendSwitchScreenShareFail = sendSwitchScreenShareFail;
		vivar.sendSwitchScreenShareState = sendSwitchScreenShareState;
		vivar.requestPCScreenShare = requestPCScreenShare;
		vivar.requestRecordingPCScreen = requestRecordingPCScreen;
		vivar.updateProfileImg = updateProfileImg;
		vivar.updateStat = updateStat;
		vivar.getRTCConnections = getRTCConnections;
		vivar.getMemberStream = getMemberStream;
		vivar.getAudioTracksAll = getAudioTracksAll;
		vivar.getAudioTrack = getAudioTrack;
		vivar.getLocalAudioTrack = getLocalAudioTrack;
		vivar.getLocalPCStream = getLocalPCStream;
		vivar.setWorker = setWorker;
		vivar.getWorker = getWorker;
		vivar.getCallUId = getCallUId;
	},
	init: init,
	processSignalingProtocol: processSignalingProtocol,
	setIceServer: setIceServer,
};